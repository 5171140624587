import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"
import "firebase/functions"

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

const firestore = app.firestore()

export const database = {
  campaigns: firestore.collection("campaigns"),
  accounts: firestore.collection("accounts"),
  users: firestore.collection("users"),
  invites: firestore.collection("invites"),
  assets: firestore.collection("assets"),
  feeds: firestore.collection("feeds"),
  streams: firestore.collection("streams"),
  notifications: firestore.collection("notifications"),
  thirdPartyApprovals: firestore.collection("third_party_approvals"),
  rightRequests: firestore.collection("right_requests"),
  confirmations: firestore.collection("confirmations"),
  formatDoc: doc => {
    return { id: doc.id, ...doc.data() }
  },
  increment: firebase.firestore.FieldValue.increment(1),
  decrement: firebase.firestore.FieldValue.increment(-1),
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
}
export const storage = app.storage()
export const auth = app.auth()
export const analytics = app.analytics()
export const functions = app.functions()

export default app
